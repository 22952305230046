.App {
  text-align: center;
}

/* .app-container {
  height: 99vh;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.nav_logo {
  width: 164px;
  height: 137px;
  margin-left: 56px;
  margin-top: 48px;
}

.landing_container {
  margin: 30px auto;
  width: 60%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.landing_content {
  /* width: 45%; */
  color: white;
}

.landing_content h1 {
  font-size: 64px;
}

.landing_content h2 {
  font-size: 32px;
}

/* .app_store {
  margin-top: 50px;
  display: flex;
  justify-content: center;
} */

.container {
  color: white;
  width: 90%;
  margin: 0 auto;
}

/* footer {
  margin: 0 auto;
  color: white;
  display: flex;
  width: 60%;
} */

/* .footer_container {
  width: 30%;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.footer_links {
  display: flex;
  justify-content: space-between;
}

.link {
  text-decoration: none;
}

.link:link {
  color: white;
}

.link:visited {
  color: white;
}

.link:active {
  color: white;
}

.link:hover {
  color: orange;
}

.downlod-icons {
  width: 176px;
  height: 46px;
}

/* .email-form {
  display: flex;
  justify-content: baseline;
  margin: 6px 0;
}

.email-form input {
  width: 210px;
  height: 32px;
  border: none;
  border-radius: 7px 0 0 7px;
}

.email-form input::placeholder {
  padding-left: 6px;
  font-size: 16px;
}

.input-text {
  font-size: 18px;
  padding-left: 8px;
}

.email-form button {
  width: 110px;
  height: 34px;
  border: none;
  background: #f4173a;
  color: #fff;
  border: none;
  border-radius: 0 7px 7px 0;
  font-size: 20px;
  cursor: pointer;
} */

.hidden {
  display: none;
}